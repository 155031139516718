import { useState, useEffect } from "react";
import axios from "axios";
import { boolean, string } from "yup";


const useSavePayload = (formValues, Base_url) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);

  const BASE_URL = Base_url;
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      const isFormValuesEmpty =
        !formValues || Object.keys(formValues).length === 0;
        if (isFormValuesEmpty) {
          setError(
            "Form values are empty. Please provide the required information."
          );
          setIsLoading(false);
          return;
        }
        setIsLoading(true);
        try {
          //console.log(formValues , "====")
          console.log("inside hooks", formValues);
        const response = await axios.post(
          BASE_URL,
          {tnc: formValues },
          {
            headers: {
              "adway-key": API_KEY,
            },
          }
        );

        setData(response.data);
        setSubmitted(true);
       
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error saving data.");
        setIsError(true);
      } finally {
        setIsLoading(false);
     
      }
    };

    fetchData();
  }, [formValues, API_KEY]);

  return {data, isLoading, submitted, error, isError };
};


const useFetchTnC = (formValues, Base_url) => {
  const [tncData, settncData] = useState(
    {success: boolean,
    data: {
    docid: string,
    name: string,
    customerId: string,
    serviceName: string,
    transactionid: string,
    signed: boolean,
  }});

  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);

  const BASE_URL = Base_url;
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      const isFormValuesEmpty =
        !formValues || Object.keys(formValues).length === 0;
        if (isFormValuesEmpty) {
          setError(
            "Form values are empty. Please provide the required information."
          );
          setIsLoading(false);
          return;
        }
        setIsLoading(true);
        try {
          //console.log(formValues , "====")
          // console.log("inside hooks", formValues);
        const response = await axios.post(
          BASE_URL,
          formValues,
          {
            headers: {
              "adway-key": API_KEY,
            },
          }
        );

        settncData(response.data);
        setSubmitted(true);
       
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error saving data.");
        setIsError(true);
      } finally {
        setIsLoading(false);
     
      }
    };

    fetchData();
  }, [formValues, API_KEY]);

  return {tncData, isLoading, submitted, error, isError };
};


const useSaveTourLeads = (formValues, Base_url) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);

  const BASE_URL = Base_url;
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      const isFormValuesEmpty =
        !formValues || Object.keys(formValues).length === 0;
        if (isFormValuesEmpty) {
          setError(
            "Form values are empty. Please provide the required information."
          );
          setIsLoading(false);
          return;
        }
        setIsLoading(true);
        try {
          //console.log(formValues , "====")
         // console.log("inside hooks", formValues);
        const response = await axios.post(
          BASE_URL,
          {data: formValues },
          {
            headers: {
              "adway-key": API_KEY,
            },
          }
        );

        setData(response.data);
        setSubmitted(true);
       
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error saving data.");
        setIsError(true);
      } finally {
        setIsLoading(false);
     
      }
    };

    fetchData();
  }, [formValues, API_KEY]);

  return {data, isLoading, submitted, error, isError };
};


const useUpdateTourLeads = (formValues, Base_url) => {
  const [data, setData] = useState([]);
  const [isLoadingUpdate, setIsLoading] = useState(true);
  const [updated, setUpdated] = useState(false);
  const [error, setError] = useState(null);

  const BASE_URL = Base_url;
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      const isFormValuesEmpty =
        !formValues || Object.keys(formValues).length === 0;

      if (isFormValuesEmpty) {
        setError(
          "Form values are empty. Please provide the required information."
        );
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      try {
        //console.log(formValues , "====")
        const response = await axios.post(
          BASE_URL,
          { data: formValues },
          {
            headers: {
              "adway-key": API_KEY,
            },
          }
        );
       // console.log(response);
        setData(response.data);
        setUpdated(true);
      
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(`Error fetching data. ${error}`);
        setUpdated(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [formValues, API_KEY]);

  return { data, isLoadingUpdate, updated, error };
};



const useValidateCouponCode = (couponCode) => {
  const [couponCodeValidation, setCouponCodeValidation] = useState(null);
  const [isValidatingCouponCode, setIsValidatingCouponCode] = useState(true);
  const [couponCodeSubmitted, setCouponCodeSubmitted] = useState(false);
  const [error, setError] = useState(null);
  
  const BASE_URL =
  "https://workstation.adansitravels.com/website_api_get_todays_rate";
  const API_KEY = process.env.REACT_APP_API_KEY;
  
  useEffect(() => {
    const fetchData = async () => {
      const isFormValuesEmpty =
      !couponCode || Object.keys(couponCode).length === 0;
      
      if (isFormValuesEmpty) {
        setError(
          "Form values are empty. Please provide the required information."
        );
        setIsValidatingCouponCode(false);
        return;
      }
      setCouponCodeSubmitted(false);
      setIsValidatingCouponCode(true);
      try {
        const response = await axios.get(BASE_URL, {
          data: { couponCode },
          headers: { "adway-key": API_KEY },
        });
        
        // If successful, set data and submitted state
       // console.log(couponCode, "couponCode");
        setCouponCodeValidation(response.data);
       setCouponCodeSubmitted(true);
      } catch (err) {
        // If an error occurs, store the error message
        
        setError(err.message);
      } finally {
        // Loading is complete
        setIsValidatingCouponCode(false);
      }
    };

    fetchData(); // Call the async function
  }, [couponCode]); // Empty dependency array means it only runs once when the component mounts

  // Return the state
  return { couponCodeValidation, isValidatingCouponCode, couponCodeSubmitted, error };
};



const useCurrencyRate = () => {
  const [currencyRate, setCurrencyRate] = useState(null);
  const [isLoadingCurrencyRate, setIsLoadingCurrencyRate] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const BASE_URL =
    "https://workstation.adansitravels.com/website_api_get_todays_rate";
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(BASE_URL, {
          headers: { "adway-key": API_KEY },
        });

        // If successful, set data and submitted state
        setCurrencyRate(response.data);
        setSubmitted(true);
      } catch (err) {
        // If an error occurs, store the error message
        setError(err.message);
      } finally {
        // Loading is complete
        setIsLoadingCurrencyRate(false);
      }
    };

    fetchData(); // Call the async function
  }, []); // Empty dependency array means it only runs once when the component mounts

  // Return the state
  return { currencyRate, isLoadingCurrencyRate, submitted, error };
};



export {useFetchTnC, useSavePayload, useSaveTourLeads, useUpdateTourLeads, useValidateCouponCode, useCurrencyRate };
