import React, { useState } from "react";
import { useSavePayload } from "hooks/leads"; // Ensure correct import of your hook
import Spinner from "components/Adways/ContentLoaders/formSpinner";

const TermsAcceptance = ({ payload }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [savedPayload, setSavedPayload] = useState({});

//   console.log("Payload", payload);
  payload.signed = payload.signed || false;

  const { data, isLoading, submitted, isError } = useSavePayload(
    savedPayload,
    "https://workstation.adansitravels.com/add_signed_tncs" // Replace with actual API endpoint
  );

  const handleAccept = () => {
    console.log("Accepting T&C");
    setSavedPayload(payload);
    setIsSubmitting(true);
    setIsSuccess(false);
  };

  return (
    <div className="max-w-xl mx-auto mt-10 p-6 pb-20 center justify-items-center rounded-lg">
      <h2 className="text-2xl font-semibold mb-4 text-left">
        Terms & Conditions Acceptance
      </h2>
      <table className="w-full border-collapse border border-gray-300">
        <tbody>
          <tr className="border-b border-gray-300">
            <td className="px-4 py-2 text-lg font-medium">Customer Name:</td>
            <td className="px-4 py-2 font-normal">{payload.name}</td>
          </tr>
          <tr className="border-b border-gray-300">
            <td className="px-4 py-2 text-lg font-medium">Service Name:</td>
            <td className="px-4 py-2 font-normal">{payload.serviceName}</td>
          </tr>
          {/* <tr className="border-b border-gray-300">
            <td className="px-4 py-2 text-lg font-medium">Transaction ID:</td>
            <td className="px-4 py-2 font-normal">{payload.transactionid}</td>
          </tr> */}
        </tbody>
      </table>

      {/* <button
        onClick={handleAccept}
        className={`w-2xl  ${
          isLoading
            ? ""
            : !submitted && (payload.signed === false)
            ? "bg-green-600 hover:bg-green-700"
            : "bg-gray-400 hover:bg-gray-500"
        } text-white font-bold py-2 px-4 rounded mt-4`}
        disabled={submitted || isLoading || payload.signed === true}
      >
        {isLoading ? (
          <div className="z-50">
            <Spinner />
          </div>
        ) : !submitted && (payload.signed === false)? (
          "Yes, I Accept the T&C"
        ) : (
          "Please note that you have already agreed to the terms and conditions."
        )}
      </button> */}

{payload.signed ? (
  <p className="text-gray-600 mt-4">
    You have already agreed to the terms and conditions.
  </p>
) : (
  <button
    onClick={handleAccept}
    className={`w-2xl ${
      isLoading ? "" : "bg-green-600 hover:bg-green-700"
    } text-white font-bold py-2 px-4 rounded mt-4`}
    disabled={isLoading}
  >
    {isLoading ? (
      <div className="z-50">
        <Spinner />
      </div>
    ) : (
      "Yes, I Accept the T&C"
    )}
  </button>
)}


      {submitted && (
        <p className="text-green-600 mt-4 text-center">
          Success! Terms accepted.
        </p>
      )}
      {isLoading && !submitted && (
        <p className="text-red-600 mt-4 text-center">Submitting consent...</p>
      )}
    </div>
  );
};

export default TermsAcceptance;
