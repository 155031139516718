import React, { useEffect, useRef, useState } from "react";
  import { useLocation } from "react-router-dom";
import TermsAcceptance from "./TermsAcceptance";
import { useFetchTnC } from "hooks/leads"; // Ensure correct import of your hook
import Spinner from "components/Adways/ContentLoaders/formSpinner";
import { set } from "lodash";




interface Section {
  id: string;
  title: string;
  content: string;
  subsections?: Section[];
}

const termsAndConditions: Section[] = [
  {
    id: "introduction",
    title: "Introduction",
    content: `<p>The Client's contractual agreement with <strong>Adansi Travels Ltd</strong> (referred to as the <strong>"Company"</strong>) is established on the following terms and conditions. Please review these conditions as they outline the rights and responsibilities of both parties.</p>

<p>By acknowledging and accepting these terms and conditions, the Client affirms that they have either personally read them or ensured that they have been read, explained, and interpreted to them in a language they comprehend.</p>

<p>By selecting the <strong>"I Accept"</strong> box, the Client affirms their understanding and agreement to the specified terms and conditions.</p>

<p>These terms and conditions apply to all travel arrangements facilitated by the Company, and they take precedence over any prior agreements between the Client and representatives of the Company.</p>

<p>By consenting to these terms, the Client agrees to be bound by the terms and conditions stipulated below.</p>
`,
  },
  {
    id: "definitions",
    title: "1. Definitions",
    content: `<p><strong>1.1.</strong> <strong>"Child"</strong> means a person between the ages of 2 years and 11 years for the purposes of the trip. Children 12 years of age and above are considered adults by most hotels and tour sites and or attraction centers and by airlines.</p>

<p><strong>1.2.</strong> <strong>"Infant"</strong> means a person who is less than 2 years at the time of travel.</p>

<p><strong>1.3.</strong> <strong>"Client"</strong> means a person who has signed up for and paid for a trip with the Company.</p>

<p><strong>1.4.</strong> <strong>"Force Majeure"</strong> means an event that could not have reasonably been anticipated and planned for, and which is beyond the control of, and occurs without the fault or negligence of, the party failing to perform including, without limitation, trade embargos, war, military or international peacekeeping intervention, acts of God, fire, explosion, strikes or other labor disputes, riots or other civil disturbances, or voluntary or involuntary compliance with any law, order, regulation or request of any governmental authority which wholly or partly prevents the affected party from performing its obligations hereunder.</p>

<p><strong>1.5.</strong> <strong>"Group Hotel"</strong> means the accommodation provided for Clients.</p>

<p><strong>1.6.</strong> <strong>"Host Country"</strong> means the country the Company shall visit and tour with its Clients.</p>

<p><strong>1.7.</strong> <strong>"Partner Agent"</strong> means others whose services are necessary for a successful trip and may include, airlines, hotels, transportation companies, restaurants, etc.</p>

<p><strong>1.8.</strong> <strong>"Promotional Price"</strong> means the reduced price off the recommended retail price of the Tour Package, which a Client would be entitled to, should they make all payments in relation to the Tour Package before or on a date specified by the Company.</p>

<p><strong>1.9.</strong> <strong>"Service Charge"</strong> means the fees charged for the services offered by the Company.</p>

<p><strong>1.10.</strong> <strong>"Special Arrangements"</strong> means specific accommodations or adjustments made to standard Tour Packages to cater to the unique requirements, preferences, or needs of the Client.</p>

<p><strong>1.11.</strong> <strong>"Tour"</strong> means a tour of the Host Country.</p>

<p><strong>1.12.</strong> <strong>"Tour Dates"</strong> means dates that the Company has advertised for a particular Tour Package.</p>

<p><strong>1.13.</strong> <strong>"Tour Packages"</strong> means a particular trip to a destination whether domestic or international covering a number of travel services as advertised.</p>

<p><strong>1.14.</strong> <strong>"Tour Package Price"</strong> means the recommended retail price of the Tour Package.</p>
`,
  },
  {
    id: "tour-package",
    title: "2. Tour Package",
    content: `<p><strong>2.1.</strong> <strong>Company</strong> promotes a range of <strong>Tour Packages</strong>, each featuring distinctive prices, itineraries, and dates among others.</p>
<p><strong>2.2.</strong> The <strong>Client</strong> has the freedom to choose packages that align with their preferences or can request the <strong>Company</strong> to tailor a package specifically for them.</p>
<p><strong>2.3.</strong> The <strong>Tour Packages</strong> generally cover the <strong>Client's</strong> daily breakfast while on <strong>Tour</strong>. <strong>Clients</strong> are responsible for arranging their lunch and dinner unless explicitly specified otherwise in the travel itinerary. The <strong>Company</strong> may suggest dining options during the tour, but it does not assure the safety and quality of the food offered at those places. <strong>Clients</strong> are advised to adequately budget for their meals and related expenses throughout the trip.</p>
<p><strong>2.4.</strong> The <strong>Company</strong> will not offer any special treatment to the <strong>Client</strong> unless the <strong>Client</strong> explicitly communicates their special needs to the <strong>Company</strong> or unless such special treatment is legally required by the <strong>Host Country</strong> for this category of <strong>Client</strong>.</p>
<p><strong>2.5.</strong> If there arises a need to modify any selected package due to reasons such as unavailability of accommodation or flights, dissatisfaction with a <strong>Partner Agent</strong>, alterations in the <strong>Host Country's</strong> policies, or requests from the general tour group, the <strong>Company</strong> will inform the <strong>Client</strong> either individually or as part of a whole group before or during the trip. The <strong>Client's</strong> consent and preferences will be considered, but the collective interest of the group will take precedence when traveling in a group.</p>
`,
  },
  {
    id: "tour-dates",
    title: "3. Tour Dates",
    content: `
    <p><strong>3.1.</strong> The <strong>Client</strong> shall adhere to the specified <strong>Tour dates</strong> corresponding to their chosen packages.</p>
<p><strong>3.2.</strong> The <strong>Client</strong> may request a change of dates where they experience sudden unexpected circumstances that significantly affect their ability to embark on the tour. Such requests stemming from extenuating circumstances will be considered by the <strong>Company</strong>. However, it is important to note that the fulfillment of these requests is contingent upon the availability of <strong>Tour Packages</strong> during the requested time frame and will require extra payment to cover the cost of changes to flight or hotel or any other parts of the services affected. Examples of extenuating circumstances include but are not limited to serious health issues and family emergencies, including instances of bereavement.</p>
<p><strong>3.3.</strong> If the <strong>Client</strong>, in line with <strong>Clause 2.2</strong> and <strong>3.2</strong>, requests for a curated package, they shall provide the <strong>Company</strong> with their preferred tour dates. The <strong>Company</strong> shall make reasonable efforts to fulfill these requests and the client will be liable for any extra cost arising out of the requested change.</p>
<p><strong>3.4.</strong> If circumstances beyond the <strong>Company's</strong> control necessitate a change in travel dates, the <strong>Company</strong> shall within five (5) days notify all registered guests. New dates will then be provided to the <strong>Clients</strong>.</p>
 `,
  },
  {
    id: "cost-of-tours",
    title: "4. Cost of Tours",
    content: `
    <p><strong>4.1.</strong> Generally, local <strong>Tour Packages</strong> are priced in <strong>Ghana Cedis</strong>, whereas international <strong>Tour Packages</strong> are originally priced in <strong>United States Dollars</strong>, payable in <strong>Ghana Cedis</strong> at the rate prescribed by the <strong>Company</strong>.</p>

<p><strong>4.2.</strong> Individual tours will have distinct pricing. <strong>Clients</strong> may choose a <strong>Tour Package</strong> based on their preferences and budget.</p>

<p><strong>4.3.</strong> The <strong>Company</strong> is under no obligation to furnish a breakdown of the costs associated with any package selected by the <strong>Client</strong>.</p>

<p><strong>4.4.</strong> The <strong>Company</strong> may offer <strong>Promotional Prices</strong> for <strong>Tour Packages</strong> which may revert to the recommended retail price of a <strong>Tour Package</strong> after a specified date.</p>

<p><strong>4.5.</strong> <strong>Family discounts</strong> can be calculated on a case-by-case basis. <strong>Clients</strong> may confirm if there are any family discounts for the <strong>Tour</strong> they have signed up for.</p>

<p><strong>4.6.</strong> The <strong>Company</strong> retains the right to inform the <strong>Client</strong> about any increase in the price of a <strong>Tour Package</strong> before or after the <strong>Client</strong> has confirmed their reservation.</p>

<p><strong>4.7.</strong> The price of the <strong>Tour Package</strong> is subject to the possibility of surcharges in certain limited circumstances, even after the <strong>Client</strong> has already paid up the original price. However, a surcharge will only be levied to allow for variations in transportation costs, including the cost of fuel, increase in normally published airfares, taxes or fees chargeable for services such as landing taxes at airports, the exchange rate applied to the particular package or if the government or regulatory body introduces an increase of taxes or changes the visa requirements or fees associated with the particular tour.</p>

<p><strong>4.8.</strong> In the case of the preceding paragraph, the increase in the <strong>Tour Package Price</strong> shall be borne by the <strong>Client</strong>.</p>
`,
  },
  {
    id: "payment",
    title: "5. Payment",
    content: `
   <p><strong>5.1.</strong> The payment made by the <strong>Client</strong> grants them access solely to the content of the chosen <strong>tour</strong> as advertised in the <strong>Company's</strong> official channels.</p>

<p><strong>5.2.</strong> The <strong>Client</strong> may make payment for the trip in <strong>United States Dollars</strong> or in <strong>Ghana cedis</strong> at the prevailing exchange rate which shall be communicated to them by the <strong>Company</strong>.</p>

<p><strong>5.3.</strong> The <strong>Client</strong> shall make full payment for the package at least two weeks before the scheduled trip.</p>

<p><strong>5.4.</strong> Installment payments for <strong>Tour Packages</strong> that are made after the due date will incur interest, the details of which will be communicated to the <strong>Client</strong> by the <strong>Company</strong>.</p>

<p><strong>5.5.</strong> If the <strong>Client</strong> does not make the full payment for the chosen <strong>Tour Package</strong> two weeks prior to the scheduled trip, the <strong>Company</strong> reserves the right to consider it as a cancellation by the <strong>Client</strong>.</p>

<p><strong>5.6.</strong> The <strong>Client</strong> may make payments at <a href="http://www.adansitravels.com/payments">www.adansitravels.com/payments</a> with a <strong>Visa Card</strong> or <strong>MasterCard</strong>.</p>

<p><strong>5.7.</strong> Alternatively, the <strong>Client</strong> may make payments via cheque or wire transfer to the following accounts specified below:</p>

<p style="margin: 10px; margin-left: 20px;">
<strong>a.</strong> Bank: <strong>ECOBANK</strong><br>
<strong>b.</strong> Account Name: <strong>Adansi Travels Limited</strong><br>
<strong>c.</strong> USD Account No: <strong>3441001155752</strong><br>
<strong>d.</strong> Cedi Account Number: <strong>1441001155751</strong><br>
<strong>e.</strong> Branch: <strong>Okponglo- East Legon</strong><br>


<p><strong>5.8.</strong> The <strong>Client</strong> shall, after making payments, forward payment receipts to <strong>accounts@adansitravels.com</strong> or to the WhatsApp number of the <strong>Company</strong> staff that assisted them with the booking. Please note that only payments made through the <strong>Company’s</strong> official bank accounts or via approved channels will be acknowledged as payments made for the <strong>Tour</strong>.</p>

<p><strong>5.9.</strong> Please note that only <strong>Clients</strong> who have fully paid for the <strong>Tour Package</strong> shall be allowed on the <strong>Tour</strong>.</p>
`,
  },
  {
    id: "bookings",
    title: "6. Bookings",
    content: `
   <p><strong>6.1.</strong> Paying the specified deposit amount for a <strong>Tour Package</strong> serves as the <strong>Client's</strong> reservation, subject to the subsequent full payment of the advertised package amount.</p>

<p><strong>6.2.</strong> Upon receipt of the deposit, the <strong>Company</strong> will initiate arrangements for the <strong>Client’s</strong> trip. These arrangements encompass securing hotel accommodations, reserving seats with airlines, booking services with bus providers in the <strong>Host Country</strong>, and coordinating visits to tour sites, among other aspects.</p>

<p><strong>6.3.</strong> The <strong>Client</strong> is responsible for furnishing the <strong>Company</strong> with all necessary documents required for making bookings for the <strong>tour</strong>.</p>

<p><strong>6.4.</strong> In any of the following cases, the <strong>Company</strong> reserves the right to decline the <strong>Client’s</strong> booking:</p>

<ul style="margin: 10px; margin-left: 20px;">
    <li><strong>a.</strong> Where the <strong>Client</strong> is unable to make full payment related to their selected <strong>Tour Package</strong>.</li>
    <li><strong>b.</strong> Where the <strong>Company</strong>, after its due diligence checks, discovers that the <strong>Client</strong> is associated with a crime syndicate, a corporate racketeer, or any other organized crime.</li>
    <li><strong>c.</strong> Where the <strong>Client</strong> engages in threatening behavior or communicates in a threatening manner with the <strong>Company</strong> staff, or resorts to violence against the <strong>Company</strong> staff.</li>
</ul>
 `,
  },
  {
    id: "passport",
    title: "7. Passport",
    content: `
   <p><strong>7.1.</strong> By signing up for any of the <strong>Company's</strong> <strong>Tour Packages</strong>, the <strong>Client</strong> affirms possessing a valid travel passport, ensuring that the passport remains valid for at least six months beyond the scheduled <strong>Tour</strong> date.</p>

<p><strong>7.2.</strong> If the <strong>Client’s</strong> existing passport holds a valid visa, and the passport itself has either expired or is likely to expire during the trip, the <strong>Client</strong> may obtain a new passport. This new passport will be appended to the old one containing the visa, and both passports together will remain valid for travel purposes.</p>
 `,
  },
  {
    id: "visas",
    title: "8. Visas",
    content: `
   <p><strong>8.1.</strong> Please note that the <strong>Company</strong> does not guarantee visas for any of its <strong>Tours</strong> as the visas are issued at the discretion of the <strong>Consular officer</strong> for the particular country.</p>

<p><strong>8.2.</strong> The <strong>Company</strong> may assist <strong>Clients</strong> who are embarking on an international <strong>Tour Package</strong> and have submitted the required documents to acquire a visa from the <strong>Host Country</strong> unless the <strong>Host Country</strong> does not require a visa. Examples of such visa-exempt countries include <strong>Kenya</strong>, <strong>Singapore</strong>, <strong>Maldives</strong>, and <strong>Mauritius</strong> among others.</p>

<p><strong>8.3.</strong> The <strong>Client</strong> shall provide the <strong>Company</strong> with personal documents such as <strong>Bank Statements</strong> and <strong>proof of Employment</strong> among others for the visa application. The <strong>Client</strong> must ensure that the documents submitted for the visa application are either original or certified true copies.</p>

<p><strong>8.4.</strong> In most cases, the fee associated with obtaining a visa is incorporated into the <strong>Tour Package Price</strong>. This fee is non-refundable, irrespective of whether the <strong>Client</strong> secures the visa or not.</p>

<p><strong>8.5.</strong> However, visa fees for <strong>Europe</strong> are not included in the <strong>Tour Package</strong> and <strong>Clients</strong> may pay such fees at the embassy or the application center.</p>

<p><strong>8.6.</strong> The under listed are the visa consultation fees for <strong>Clients</strong>:</p>
<ul style="margin: 10px; margin-left: 20px;">
    <li><strong>a.</strong> USA:     <strong>USD 500</strong></li>
    <li><strong>b.</strong> CANADA:  <strong>USD 500</strong></li>
    <li><strong>c.</strong> UK:      <strong>USD 300</strong></li>
    <li><strong>d.</strong> EUROPE:  <strong>USD 300</strong></li>
</ul>

<p><strong>8.7.</strong> The <strong>Client</strong> agrees to indemnify and release the <strong>Company</strong> from any liability arising from errors or omissions in the visa application, including the submission of incorrect or counterfeit documents.</p>

<p><strong>8.8.</strong> In the event of a <strong>Client's</strong> visa being declined due to the submission of fraudulent or counterfeit documents, the <strong>Company</strong> reserves the right to impose a surcharge, and the <strong>Client</strong> will forfeit all deposits made for the trip.</p>

<p><strong>8.9.</strong> The <strong>Client</strong> undertakes to use visas acquired through the <strong>Company’s</strong> services only for the <strong>Tour</strong> and such visas shall not be valid for employment or other immigration purposes unless the visa specifically states so.</p>

<p><strong>8.10.</strong> The <strong>Client</strong> additionally commits not to stay beyond the allowed duration or misuse their visa. In cases of overstaying or visa abuse, such as attempting to evade authorities, the <strong>Company</strong> reserves the right to collaborate with the relevant Embassy. The <strong>Company</strong> may furnish the Embassy with necessary information to assist in tracking the <strong>Client</strong>.</p>
 `,
  },
  {
    id: "hotel",
    title: "9. Hotel",
    content: `
    <p><strong>9.1.</strong> Hotel Accommodation is based on twin/double sharing in the star/category chosen.</p>

<p><strong>9.2.</strong> The <strong>Company</strong> shall ensure all sharing rooms have two separate beds for <strong>Clients</strong> of the same sex sharing and double beds for couples.</p>

<p><strong>9.3.</strong> A <strong>Client</strong> travelling alone and who may not want to share a room with any person may make extra payment as a single supplement, and such accommodation may be arranged for the <strong>Client</strong> on arrival at the hotel.</p>

<p><strong>9.4.</strong> As a standard practice, hotels have their check-in from <strong>2:00-3:00pm</strong> and the check-out from <strong>11:00am-12:00pm</strong>. Early check-in or late check-out is entirely based on the availability of empty rooms in the hotel and cannot be guaranteed by the <strong>Company</strong>.</p>

<p><strong>9.5.</strong> Where a flight arrives earlier than scheduled and it becomes necessary in the interest of the <strong>Client</strong> to do so, the <strong>Company</strong> may request the <strong>Client</strong> to pay for an extra night to enable the guest check-in earlier than agreed with the hotel management.</p>

<p><strong>9.6.</strong> For flights arriving very early, the <strong>Client</strong> may book an extra night earlier than their arrival date to guarantee early check-in. For flights departing very late, the <strong>Client</strong> may book an extra night beyond the advertised room nights to guarantee late check-out.</p>

<p><strong>9.7.</strong> All mandatory hotel taxes are included in the <strong>Company’s</strong> charges, and where it is not, the <strong>Client</strong> shall be informed accordingly. The <strong>Client</strong> shall then be responsible for ensuring such taxes are settled with the hotel.</p>

<p><strong>9.8.</strong> The <strong>Client</strong> may pay such monies directly to the hotel upon check-in or check-out.</p>

<p><strong>9.9.</strong> Where a <strong>Client</strong> is required by a host country to pay a tax, the same shall be communicated to the <strong>Client</strong> upon booking the trip or before, during, or after the trip depending on the circumstances. The <strong>Client</strong> shall pay such taxes directly upon arrival in the host country or in the manner prescribed by the host country.</p>

<p><strong>9.10.</strong> A <strong>Client</strong> is required to choose a hotel accommodation as included in a <strong>Tour Package</strong>.</p>

<p><strong>9.11.</strong> A <strong>Client</strong> who chooses a particular hotel accommodation is deemed to have chosen the accommodation per their means and/or budget and may not be allowed to alter their choice after the room has been booked and paid for by the <strong>Company</strong>.</p>

<p><strong>9.12.</strong> The hotel accommodations available for <strong>Tour Packages</strong> include but are not limited to:</p>
<ul style="margin: 10px; margin-left: 20px;">
    <li><strong>i.</strong> A 3-star hotel’s standard services</li>
    <li><strong>ii.</strong> A 4-star hotel’s superior services</li>
    <li><strong>iii.</strong> A 5-star hotel’s luxury services.</li>
</ul>

<p><strong>9.13.</strong> The services provided by these hotels may differ from host country to host country, and the <strong>Company</strong> is not responsible and/or liable for a service considered by a <strong>Client</strong> to be below standard or bad or poor.</p>

<p><strong>9.14.</strong> A <strong>Client</strong> on a <strong>Tour</strong> may be given one standard shared room hotel accommodation with another <strong>Client</strong> of the same sex.</p>

<p><strong>9.15.</strong> A <strong>Client</strong> who wishes to sleep in a room alone and/or in a room other than the one the <strong>Company</strong> has organized for that trip may make such requests before the trip or during the trip and shall pay the necessary extra cost where the room so requested is available and the same has been secured by the <strong>Company</strong>.</p>

<p><strong>9.16.</strong> Where a <strong>Client</strong> requests to lodge in a hotel other than one booked for a particular <strong>Tour</strong>, the <strong>Company</strong> shall consider whether or not the request may affect the travel itinerary for the <strong>Tour</strong> and may discuss with the <strong>Client</strong> accordingly.</p>

<p><strong>9.17.</strong> Where a request to lodge in a hotel other than one booked for a trip is made and such request may affect the <strong>tour itinerary</strong> negatively, the <strong>Company</strong> may decline such a request and the <strong>Client</strong> shall lodge in the group hotel.</p>

<p><strong>9.18.</strong> Where a hotel accommodation advertised for a particular <strong>Tour Package</strong> is not available due to circumstances beyond the <strong>Company’s</strong> control, the <strong>Company</strong> shall endeavor to secure a hotel of similar class and standard for the <strong>Client</strong>.</p>
`,
  },
  {
    id: "flights",
    title: "10. Flights",
    content: `
    <p><strong>10.1.</strong> Typically, the cost of flights is included in the <strong>Tour Package</strong> price.</p>

<p><strong>10.2.</strong> Nevertheless, it is important to note that the price of <strong>Tour Packages</strong> to the <strong>UK</strong>, <strong>USA</strong>, and <strong>Canada</strong> might not cover flight expenses. This is to provide <strong>Clients</strong> with the flexibility to return from the trip at a more convenient time, particularly if the scheduled return date is inconvenient for them.</p>

<p><strong>10.3.</strong> Once the visa has been approved, and the <strong>Client</strong> opts for a return date different from the scheduled conclusion of the <strong>Tour</strong>, they are at liberty to manage their own arrangements, including securing flight reservations through the <strong>Company</strong>.</p>

<p><strong>10.4.</strong> If the <strong>Client</strong> chooses to arrange their own flights, the <strong>Company</strong> shall not be held responsible for any potential issues, such as flight delays or cancellations, missed hotel check-ins resulting from flight delays, and missed airport transfers to the hotel due to missed flights.</p>
 `,
  },
  {
    id: "cancellation",
    title: "11. Cancellation",
    content: `
    <p><strong>11.1.</strong> The <strong>Client</strong> may not cancel their trip 14 days before the scheduled departure date.</p>

<p><strong>11.2.</strong> The <strong>Client</strong> may cancel their subscription to a <strong>Tour Package</strong> in line with the following Clause, where:</p>
<ul style="margin: 10px; margin-left: 20px;">
    <li>a. The <strong>Client</strong> experiences unexpected emergencies, including but not limited to suffering a serious injury or ailment.</li>
    <li>b. The Government of the destination country issues travel advisories, warnings, travel restrictions, or entry bans.</li>
    <li>c. The <strong>Company</strong> makes significant changes to the <strong>Tour</strong> itinerary provided that were not initially agreed upon.</li>
    <li>d. Natural disasters, acts of terrorism, political unrest, or other force majeure events occur, making the <strong>Tour</strong> unsafe or impracticable.</li>
</ul>

<p><strong>11.3.</strong> To cancel their subscription, the <strong>Client</strong> is required to inform the <strong>Company</strong> by sending an email to the <strong>Tour Leader</strong> using the <strong>Tour Leader's</strong> designated email address. The cancellation notice from the <strong>Client</strong> becomes valid only upon receipt in writing by the <strong>Company</strong>.</p>

<p><strong>11.4.</strong> The <strong>Company</strong> may cancel a scheduled <strong>Tour</strong> where:</p>
<ul style="margin: 10px; margin-left: 20px;">
    <li>a. The Government of the destination country imposes travel advisories, warnings, travel restrictions, or entry bans, or</li>
    <li>b. There are events beyond the <strong>Company’s</strong> control, such as natural disasters, extreme weather conditions, acts of terrorism, political unrest, or any other force majeure event, or</li>
    <li>c. There are safety concerns at the destination, such as disease outbreaks, civil unrest, or other hazardous conditions, or</li>
    <li>d. There are unexpected and irreconcilable logistical issues.</li>
</ul>

<p><strong>11.5.</strong> The <strong>Company</strong> will notify the <strong>Clients</strong> of a tour cancellation through a phone call, text message, or email. This communication will be promptly conveyed as soon as the decision to cancel is finalized.</p>
 `,
  },
  {
    id: "refund_policy",
    title: "12. Refund Policy",
    content: `
   <p><strong>12.1.</strong> The <strong>Company</strong> will refund any payments made towards a <strong>Tour</strong>, deducting administrative charges and visa application fees where the <strong>Client’s</strong> visa application has been denied for reasons other than fraud or the submission of counterfeit documents. The refund policy shall be subject to periodic review.</p>

<p><strong>12.2.</strong> Where the <strong>Client</strong> does not participate in the trip for any reason whatsoever, the <strong>Company</strong> will not refund any payments made for air tickets. The <strong>Client</strong> must verify the ticket conditions with the <strong>Company</strong> during the booking process.</p>

<p><strong>12.3.</strong> The <strong>Company</strong> reserves the right to refund monies paid if the <strong>Client</strong> cancels their trip before the booking and confirmation of flight ticket and accommodation. The refunded amount will be subject to the deduction of administrative fees, as communicated to the <strong>Client</strong> by the <strong>Company</strong>.</p>

<p><strong>12.4.</strong> The <strong>Client</strong> may also request that the money paid be used for another <strong>Tour</strong> with the <strong>Company</strong>. This alternative arrangement may incur additional costs for the <strong>Client</strong>.</p>

<p><strong>12.5.</strong> Where the visa is granted and the <strong>Client</strong> refuses to travel, there shall be no refunds of monies paid for the trip to the <strong>Client</strong>.</p>

<p><strong>12.6.</strong> In cases where visa refusal stems from the submission of fraudulent documents, such as counterfeit Bank Statements provided by <strong>Clients</strong>, no refunds of payments will be issued. The <strong>Company</strong> may report the <strong>Client</strong> to the Embassy and the <strong>Company</strong> may pursue legal remedies to restore its reputation against the implicated <strong>Client</strong>.</p>

<p><strong>12.7.</strong> The cost of flights is non-refundable after the <strong>Company</strong> has issued and booked flight tickets.</p>
 `,
  },
  {
    id: "travel_insurance",
    title: "13. Travel Insurance",
    content: `
   <p><strong>13.1.</strong> The <strong>Company</strong> highly recommends the <strong>Client</strong> to buy an insurance policy for their <strong>Trip</strong>.</p>

<p><strong>13.2.</strong> The <strong>Client</strong> may contact the <strong>Company</strong> for help in choosing an appropriate travel insurance policy, and this assistance is provided free of charge.</p>

<p><strong>13.3.</strong> The <strong>Travel insurance</strong> must cover cancellation costs, medical expenses, and any adventurous activities planned during the <strong>Tour</strong>.</p>

<p><strong>13.4.</strong> The <strong>Client</strong> must ensure that the <strong>Travel Insurance</strong> they purchase is suitable and adequate for their specific needs.</p>

<p><strong>13.5.</strong> The <strong>Client</strong> is advised to carefully read and understand the terms of their insurance policy.</p>

<p><strong>13.6.</strong> Please note that the <strong>Company</strong> is obligated to activate and facilitate travel insurance during emergencies. However, it is explicitly stated that the <strong>Company</strong> cannot guarantee the effectiveness of the insurance policy.</p>
 `,
  },
  {
    id: "health",
    title: "14. Health",
    content: `
   <p><strong>14.1.</strong> The <strong>Client</strong> is responsible for meeting any vaccination requirements imposed by the destination. This may involve obtaining specific vaccinations or immunizations mandated by local authorities to ensure public health and safety.</p>

<p><strong>14.2.</strong> The <strong>Client</strong> is also responsible for meeting the medical insurance requirements of each travel destination. This involves securing the necessary medical insurance coverage that complies with the destination's regulations and standards.</p>

<p><strong>14.3.</strong> The onus is on the <strong>Client</strong> to inform the <strong>Company</strong> about any health conditions that they may have. This disclosure is important because certain health conditions might pose risks not only to the individual but also to the other <strong>Clients</strong> during the <strong>Trip</strong>.</p>

<p><strong>14.4.</strong> The <strong>Client</strong> is encouraged to notify the <strong>Company</strong> if any special assistance is required due to their health condition. This could involve accommodation and/or medical provisions or other necessary arrangements.</p>

<p><strong>14.5.</strong> The <strong>Company</strong> shall take reasonable steps to make adequate provisions based on the information provided by the <strong>Client</strong>.</p>

<p><strong>14.6.</strong> Notwithstanding Clause 14.5, the <strong>Company</strong> does not guarantee that it will always be in a position to make provisions requested by the <strong>Client</strong>.</p>

<p><strong>14.7.</strong> Special provisions, if required, may be subject to an additional charge for the <strong>Client</strong>.</p>
   `,
  },
  {
    id: "images_and_marketing",
    title: "15. Images and Marketing",
    content: `
  <p><strong>15.1.</strong> Participation in a group trip organized by the <strong>Company</strong>, its agents, Partner Agents, and representatives grants the <strong>Company</strong> the right to capture images or feature videos, photos, etc., of the <strong>Client</strong> in various capacities such as an individual, family, couple, group, etc., for inclusion in its album that may be used for business purposes.</p>

<p><strong>15.2.</strong> The <strong>Client</strong> hereby gives consent for the use of any such images or features, videos, photos, etc., for promotional purposes, including promotions, promotional materials, brochures, advertisements, future Tours, etc.</p>

<p><strong>15.3.</strong> The <strong>Client</strong> also consents to the possibility that images, photos, or videos taken by other participants, the <strong>Company</strong>’s authorized agents, or representatives may include their likeness, appearance, preferences, etc., and may be featured in an appropriate manner.</p>

<p><strong>15.4.</strong> The <strong>Client</strong> acknowledges that they are not entitled to receive fees, royalties, compensation, etc., for the utilization of their photos, images, videos, features, whether taken individually, as part of a group, family, etc., for the <strong>Company</strong>’s business purposes.</p>

<p><strong>15.5.</strong> The <strong>Client</strong> consents and agrees that the <strong>Company</strong>, or its authorized agents, contractors, sub-contractors, and assigns, may reproduce photographs, images, videos, etc., taken of them alone, with their family, in a group, or as a couple, for any reasonable purpose related to the <strong>Company</strong>'s business.</p>

<p><strong>15.6.</strong> Furthermore, the <strong>Client</strong> agrees and consents that images, photos, etc., taken of them as an individual, family, or group may be utilized for marketing, promotions, and the creation of promotional materials across any medium, whether currently known or developed in the future, without any further obligation or compensation payable to them.</p>
  `,
  },
  {
    id: "miscellaneous",
    title: "17. Miscellaneous",
    content: `
   <p><strong>17.1.</strong> The <strong>Company</strong> serves solely as an intermediary for <strong>Customers</strong> regarding visa arrangements, flight bookings, hotel accommodations, sightseeing Tours, and various modes of transportation. While the <strong>Company</strong> endeavors to act with utmost diligence in these matters, it assumes no liability for any issues arising from visa arrangements, flight bookings, hotel accommodations, sightseeing Tours, or transportation. By accepting these terms, the <strong>Client</strong> agrees to absolve the <strong>Company</strong> from any such liabilities.</p>

<p><strong>17.2.</strong> In appreciation of the services and arrangements provided by the <strong>Company</strong>, the <strong>Client</strong>, their heirs, personal representatives, or assigns, hereby release, waive, discharge, and indemnify the sales staff, the <strong>Agency</strong>, as well as its owners, officers, directors, agents, contractors, subcontractors, and employees from all claims, actions, or losses. This includes but is not limited to, bodily injury, property damage, wrongful death, loss of services, lost profits, consequential, exemplary, indirect, or punitive damages arising from or during their travel in connection with the scheduled trip and any related activities.</p>

<p><strong>17.3.</strong> The <strong>Company</strong> shall not be held responsible for any death, bodily injury, illness, damage, delay, or other loss or harm to the <strong>Client</strong>’s individual, or their property, including both direct and indirect financial costs incurred.</p>

<p><strong>17.4.</strong> Additionally, the <strong>Company</strong> is not liable for the failure to initiate, execute, or fulfill any obligation owed to the <strong>Client</strong> when events including but not limited to death, delay, bodily injury (including emotional distress or injury), illness, damage, other loss or harm to individuals or property, occur as a result from an Act of God or Force Majeure.</p>

<p><strong>17.5.</strong> The <strong>Company</strong> may gather the personal information of the <strong>Client</strong> to facilitate services, processing, and booking Tours. The collection, utilization, and disclosure of information will be limited to what is reasonably necessary to enable the <strong>Company</strong> and its partnered suppliers to deliver the specific trip, products, and/or services requested by the <strong>Client</strong>.</p>

<p><strong>17.6.</strong> Upon submitting any personal information to the <strong>Company</strong>, the <strong>Client</strong> signifies their agreement with the <strong>Company</strong>'s Privacy Policy, accessible on the <strong>Company</strong>'s website at <a href="http://www.adansitravels.com/privacy-policy">www.adansitravels.com/privacy-policy</a>.</p>

<p><strong>17.7.</strong> The <strong>Company</strong> will handle all personal data in compliance with the Data Protection Act, 2012 (Act 843) of Ghana.</p>

<p><strong>17.8.</strong> The creation, existence, interpretation, execution, validity, and all aspects of this Agreement or any of its terms will be governed by the laws of the Republic of Ghana.</p>

<p><strong>17.9.</strong> The <strong>Company</strong> retains the right to modify or revise these Terms at its discretion and will publish the updated Terms on <strong>Adansi Travels</strong>' website at <a href="http://www.adansitravels.com">www.adansitravels.com</a>.</p>

<p><strong>17.10.</strong> A current version of these Terms, as amended, can be accessed at any time on the <strong>Company</strong>'s website and will be provided to the <strong>Client</strong> upon written request. Upon receipt of the revised Terms, the <strong>Client</strong> is considered to have acknowledged and accepted any changes made. It is advised that the <strong>Client</strong> consult the Terms before embarking on travel to acquaint themselves with the latest version.</p>

<p><strong>17.11.</strong> Any dispute or difference whatsoever between the parties which may arise out of or in connection with this Agreement shall first be settled amicably between the parties, failing which the parties shall resort to a direct meeting between the <strong>Company</strong>'s Management and the <strong>Client</strong> within <strong>THIRTY (30)</strong> days thereof for resolution. In the event of a failure of an amicable settlement between the parties either party may refer the dispute to the Ghana Arbitration Centre for determination.</p>

<p><strong>17.12.</strong> The <strong>Client</strong> by consenting to these Terms and Conditions, hereby represents and warrants to the others and to all third-party beneficiaries of this Agreement that:
    <ul>
        <li>(a) they have the full right, capacity, and authority to enter into, deliver and perform its respective obligations under this Agreement,</li>
        <li>(b) this Agreement has been duly executed and delivered by them and is binding and enforceable on them in accordance with the terms of this Agreement, and</li>
        <li>(c) the execution, delivery, and performance of their obligations under this Agreement will not conflict with or breach the terms of any other agreement, contract, commitment, or understanding to which they are a party.</li>
    </ul>
</p>
 `,
  },
];

const TermsAndConditions: React.FC = () => {
  const [savedPayload, setSavedPayload] = useState({});
  const [transactionId, setTransactionId] = useState({});

  const location = useLocation();
  const getQueryParam = (param) => {
    const queryParams = new URLSearchParams(location.search);
    const value = queryParams.get(param);
    return value;
  };


useEffect(() => {
  setTransactionId({
    "transactionid" : getQueryParam("tnc")
});
}, []);
// console.log("transactionId", transactionId);
  const { tncData, isLoading, submitted, isError } = useFetchTnC(transactionId,
    "https://workstation.adansitravels.com/tnc_fetch_info_for_transactionid" // API endpoint
  );


  //get param from url
  useEffect(() => {
    if(submitted){
      // console.log("submitted", tncData);
      const payload =  {
        "id": tncData?.data?.docid,
        "name": tncData?.data?.name,
        "customerId": tncData?.data?.customerId,
        "serviceName": tncData?.data?.serviceName,
        "transactionid": tncData?.data?.transactionid,
        "content" : termsAndConditions,
        "signed": tncData?.data?.signed,
    }

    setSavedPayload(payload);

    }
  }, [tncData, submitted]);





  const [activeSection, setActiveSection] = useState<string | null>(
    termsAndConditions[0]?.id
  );
  const [isTOCVisible, setIsTOCVisible] = useState(true); // State for Table of Contents visibility

  const contentRef = useRef<HTMLDivElement>(null);
  const [showButton, setShowButton] = useState(false);
  const scrollableDivRe = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // if(activeSection === null)
    //   setActiveSection(termsAndConditions[0].id);
    if (contentRef.current && activeSection) {
      const sectionElement = document.getElementById(activeSection);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [activeSection]);

  // Handle scrolling and toggle back to top button within div
  const handleScroll = () => {
    if (contentRef.current) {
      if (contentRef.current.scrollTop > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };


  if (isLoading) {
    return (
    <div className="flex justify-center items-center h-screen">
      <Spinner />;

    </div>)
  }

  return (
    <>
    <div
      className={`flex  md:flex-row ${isTOCVisible ? "flex-col" : "flex-row"}`}
      >
      {" "}
      {/* Change to column layout if TOC is visible */}
      {/* Toggle Button */}
      <button
        className="absolute top-10 right-4 z-10 p-2 text-gray-700 md:hidden transition-colors duration-300 hover:text-gray-900"
        onClick={() => setIsTOCVisible(!isTOCVisible)}
        >
        {isTOCVisible ? (
          <i className="fas fa-times" style={{ fontSize: "24px" }}></i> // Font Awesome 'X' icon
        ) : (
          <i className="fas fa-bars" style={{ fontSize: "24px" }}></i> // Font Awesome 'Menu' icon
        )}
      </button>
      {/* Table of Contents - Show on Mobile when toggled */}
      <div
        className={`md:w-1/5 p-4 bg-gray-100 md:overflow-y-scrol md:block ${
          isTOCVisible ? "w-full" : "hidden"
        } md:block`}
        >
        <h2 className="text-xl font-semibold mb-2">Table of Contents</h2>
        <ul>
          {termsAndConditions.map((section) => (
            <li key={section.id} className="mb-2">
              <button
                className={`text-left w-full py-2 px-3 rounded-md transition-all duration-300 ease-in-out
                  ${
                    activeSection === section.id
                    ? "bg-gradient-to-r from-green-600 to-yellow-600 text-white"
                    : "text-gray-700 hover:bg-gradient-to-r hover:from-green-500 hover:to-yellow-500 hover:text-white"
                  }`}
                  onClick={() => {
                    setActiveSection(section.id);
                    setIsTOCVisible(false); // Hide TOC when a section is selected
                  }}
                  >
                {section.title}
              </button>
            </li>
          ))}
        </ul>
      </div>
      {/* Content - 100% width on mobile, 70% width on larger screens */}
      <div
        onScroll={handleScroll}
        className={`w-full md:h-[calc(100vh+4rem)] bg-gray-50 md:w-3/4  overflow-y-auto md:mr-20 ${
          isTOCVisible ? "block" : "flex-grow"
        }`}
        ref={contentRef}
        >
        <h1 className="text-2xl md:ml-16 md:text-3xl text-center md:text-left md:pb-3 bg-green-50 py-6 md:bg-gray-50 font-bold mb-2">
          Terms and Conditions
        </h1>
        {termsAndConditions.map((section) => (
          <div className="md:mx-16">
            <div key={section.id} id={section.id} className="mb-8 mx-4">
              <h2 className="text-2xl font-semibold mb-4">{section.title}</h2>
              <div
                className="leading-loose ml-10"
                dangerouslySetInnerHTML={{ __html: section.content }}
                />
            </div>
          </div>
        ))}

        {showButton && (
          <button
          id="backToTop"
          className="fixed bottom-4 right-4 p-2 bg-green-600 text-white rounded-full shadow-lg transition-transform duration-300 hover:scale-105 "
          onClick={scrollToTop}
          aria-label="Back to Top"
          >
            <i className="fas fa-arrow-up" style={{ fontSize: "24px" }}></i>{" "}
            {/* Font Awesome 'Up Arrow' icon */}
          </button>
        )}
        {!(Object.keys(savedPayload).length === 0) && <TermsAcceptance payload={savedPayload} />}
      </div>
    </div>
    </>
  );
};

export default TermsAndConditions;

// const TermsAndConditions: React.FC = () => {
//     const [activeSection, setActiveSection] = useState<string | null>(null);
//     const contentRef = useRef<HTMLDivElement>(null);

//     useEffect(() => {
//       if (contentRef.current && activeSection) {
//         const sectionElement = document.getElementById(activeSection);
//         if (sectionElement) {
//           sectionElement.scrollIntoView({ behavior: 'smooth' });
//         }
//       }
//     }, [activeSection]);

//     return (
//       <div className="mr-10">
//       <div className="flex h-[80vh]">
//         {/* Table of Contents - 20% width */}
//         <div className="w-1/5 p-4 bg-gray-100 overflow-y-auto fixed top-400 left-0 h-full">

//        <h2 className="text-xl font-semibold mb-2">Table of Contents</h2>
//        <ul>
//          {termsAndConditions.map((section) => (
//            <li key={section.id} className="mb-1">
//              <button
//                className={`text-left w-full py-1 px-3 rounded-md transition-all duration-300 ease-in-out
//                  ${activeSection === section.id
//                    ? 'bg-gradient-to-r from-blue-500 to-purple-500 text-white'
//                    : 'text-gray-700 hover:bg-gradient-to-r hover:from-blue-400 hover:to-purple-400 hover:text-white'
//                  }`}
//                onClick={() => setActiveSection(section.id)}
//              >
//                {section.title}
//              </button>
//            </li>
//          ))}
//        </ul>
//      </div>

//         {/* Content - 70% width */}
//         <div className="w-3/4 p-4 fixed top-16 left-80 h-[calc(100vh-4rem)] overflow-y-auto" ref={contentRef}>
//   <h1 className="text-2xl font-bold mb-4">Terms and Conditions</h1>
//   {termsAndConditions.map((section) => (
//     <div key={section.id} id={section.id} className="mb-8">
//       <h2 className="text-2xl font-semibold mb-4">{section.title}</h2>
//       <p className="whitespace-pre-wrap">{section.content}</p>
//     </div>
//   ))}
// </div>

//       </div>
//       <div className="p-40">

//       </div>
//       </div>

//     );
//   };
// export default TermsAndConditions;

// const TermsAndConditions: React.FC = () => {
//   const [activeSection, setActiveSection] = useState<string | null>(null);

//   return (
//     <div className="max-w-4xl mx-auto p-4">
//       <h1 className="text-3xl font-bold text-center mb-8">Terms and Conditions</h1>

//       <div className="bg-gray-100 p-4 rounded-lg mb-8">
//         <h2 className="text-xl font-semibold mb-4">Table of Contents</h2>
//         <ul>
//           {termsAndConditions.map((section) => (
//             <li key={section.id} className="mb-2">
//               <a
//                 href={`#${section.id}`}
//                 className="text-blue-600 hover:underline"
//                 onClick={() => setActiveSection(section.id)}
//               >
//                 {section.title}
//               </a>
//             </li>
//           ))}
//         </ul>
//       </div>

//       {termsAndConditions.map((section) => (
//         <div key={section.id} id={section.id} className="mb-8">
//          <h2 className="text-2xl font-semibold mb-4">{section.title}</h2>
//           <div className={`${activeSection === section.id ? 'block' : 'hidden'}`}>
//             <p className="whitespace-pre-wrap">{section.content}</p>
//           </div>
//           {activeSection !== section.id && (
//             <button
//               className="text-blue-600 hover:underline"
//               onClick={() => setActiveSection(section.id)}
//             >
//               Read more
//             </button>
//           )}
//         </div>
//       ))}

//       <button
//         className="fixed bottom-4 right-4 bg-blue-600 text-white px-4 py-2 rounded-full"
//         onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
//       >
//         Back to Top
//       </button>
//     </div>
//   );
// };

// export default TermsAndConditions;
